import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Mandanteneinrichtung",
  "description": "So nehmen Sie die Grundeinrichtung Ihres Karriereportal-Mandanten vor",
  "author": "Jochen Ritscher",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Unter dem Menüpunkt Einrichtung > Einstellungen > Mandant können Sie Ihren `}<a parentName="p" {...{
        "href": "/backend/tenants/1/edit"
      }}>{`Karriereportal-Mandanten einrichten`}</a>{`. `}</p>
    <p>{`Folgende Einstellungen können vorgenommen werden:`}</p>
    <h2>{`Name`}</h2>
    <p>{`Passen Sie den Namen Ihres Mandanten an, der in Überschriften, Seitentiteln und Begrüßungstexten verwendet wird. Beispiel: inriva GmbH`}</p>
    <h2>{`Host`}</h2>
    <p>{`Geben Sie den Hostnamen an, der standardmäßig verwendet werden soll, falls dieser vom Standard abweicht (optionale Einstellung)`}</p>
    <h2>{`E-Mail`}</h2>
    <p>{`Geben Sie die Absendeadresse für automatisierte E-Mails aus dem System ein. Diese Adresse wird auch in Stellenanzeigen als Kontaktadresse angegeben, denen keine Disponenten-E-Mail-Adresse zugewiesen ist.`}</p>
    <h2>{`Willkommenstext`}</h2>
    <p>{`Hier können Sie einen individuellen Willkommenstext festlegen (Nur im Portal sichtbar wenn der Baustein in Ihrem Layout aktiviert ist)`}</p>
    <h2>{`Settings`}</h2>
    <p>{`Hier können Sie individuell vereinbarte Einstellungen bei Bedarf anpassen. Nur für Administratoren.`}</p>
    <h2>{`Link zur Stellenliste auf Ihrer Website`}</h2>
    <p>{`Falls sie das inriva Karriereportal Widget auf Ihrer Website einsetzen, geben Sie bitte hier die URL an in der Sie das Widget eingebunden haben. Wechselt der Bewerber vom Karriereportal zurück zur Stellenliste, wird er zu der hier angegebenen Adresse geleitet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      